import React, { useCallback, useState } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { handleImageDrop } from '../../../../../pages/CompressImage'; 
import { useDropzone } from "react-dropzone";
import * as Yup from 'yup'
import EmojiPickerComponent from '../../../../../pages/EmojiPicker';

const CreateTemplate = ({ setOpenModal }: any) => {
    const [loading, setLoading] = useState(false)
    const [isMarketingCollapsed, setIsMarketingCollapsed] = useState(true);
    const [isUtilityCollapsed, setIsUtilityCollapsed] = useState(true);
    const [pdfUpload, setPdfUpload] = useState<File[]>([]);
    const [pdfsizeError, setPdfsizeError] = useState(false);
    const [imageUpload, setImageUpload] = useState<File[]>([]);
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [frontImageError, setFrontImageError] = useState('');
    const [error, setError] = useState('');
    const [activeStep, setActiveStep] = useState<number>(1);
    const [customButton, setCustomButton] = useState(0)
    const [websiteButton, setWebsiteButton] = useState(0)
    const [phoneButton, setPhoneButton] = useState(0)
    const [mediaType, setMediaType] = useState('');
    const [templateBody, setTemplateBody] = useState('');
    const [variableCount, setVariableCount] = useState(0); 
    const [variableInputs, setVariableInputs] = useState<string[]>([]);



    const toggleMarketingCollapse = () => {
        setIsMarketingCollapsed(!isMarketingCollapsed);
    };

    const toggleUtilityCollapse = () => {
        setIsUtilityCollapsed(!isUtilityCollapsed);
    };

    const handleMediaTypeChange = (event: any) => {
        setMediaType(event.target.value);
    };

    const handleAddVariable = (e: any) => {
        e.preventDefault();
        const updatedCount = variableCount + 1;
        setVariableCount(updatedCount);
        setTemplateBody(prevBody => `${prevBody}{{${updatedCount}}}`);
    };

    const handleVariableInputChange = (variableNumber: number, value: string) => {
        setVariableInputs(prevInputs => ({
            ...prevInputs,
            [variableNumber]: value
        }));
    };

    // JSX for rendering variable input fields
    const renderVariableInputs = () => {
        const inputs = [];
        for (let i = 1; i <= variableCount; i++) {
            inputs.push(
                <div className="mt-3 d-flex align-items-center" key={i}>
                    <label className='me-5 fs-5'>{`{{${i}}}`}</label>
                    <input
                        type="text"
                        id={`variable_${i}`}
                        className="form-control"
                        value={variableInputs[i] || ''}
                        placeholder={`Enter content for ${i}`}
                        onChange={e => handleVariableInputChange(i, e.target.value)}
                    />
                    <button className='btn btn-sm btn-light-danger ms-2' onClick={() => handleClearVariable(i)}> <KTIcon iconName='cross-square' className='fs-1' /></button>
                    <button className='btn btn-sm btn-light-danger ms-2' onClick={() => handleRemoveVariable(i)}><KTIcon iconName='trash' className='fs-3' /></button>
                </div>
            );
        }
        return inputs;
    };

    const handleClearVariable = (variableNumber: number) => {
        setVariableInputs(prevInputs => ({
            ...prevInputs,
            [variableNumber]: ''
        }));
    };

    const handleRemoveVariable = (variableNumber: number) => {
        const updatedInputs = { ...variableInputs };
        delete updatedInputs[variableNumber];
        setVariableInputs(updatedInputs);
        setVariableCount(prevCount => prevCount - 1);
        setTemplateBody(prevBody => prevBody.replace(new RegExp(`\\{\\{${variableNumber}\\}\\}`, 'g'), ''));
    };

    const handleStarClick = () => {
        setTemplateBody(prevBody => `${prevBody}**`);
    };

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        // Limiting to 1024 characters
        if (inputValue.length <= 1024) {
            // Count the number of variables in the input
            const count = (inputValue.match(/\{\{\d+\}\}/g) || []).length;
            setVariableCount(count);
            setTemplateBody(inputValue);

            // Clear input values for variables that are removed from the template body
            Object.keys(variableInputs).forEach(variableNumber => {
                const num = parseInt(variableNumber);
                if (num > count) {
                    const updatedInputs = { ...variableInputs };
                    delete updatedInputs[num];
                    setVariableInputs(updatedInputs);
                }
            });
        }
    };

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setImageUpload),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }, multiple: false,
    });

    const onDrop = useCallback((acceptedFiles: any) => {
        const videoFile = acceptedFiles[0];
        if (videoFile && videoFile.size <= 5 * 1024 * 1024) { // Check if file size is less than or equal to 2MB
            setUploadedVideo(videoFile);
            setError('');
            setFrontImageError('')
        } else {
            setError('File size must be 5MB or less.');
            setUploadedVideo(null);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'video/mp4': [] }, multiple: false });

    const handleDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if (file.size <= 2 * 1024 * 1024) { // Checking if file size is less than 2MB
                setPdfUpload([file]);
                setPdfsizeError(false);
            } else {
                setPdfsizeError(true);
            }
        }
    };

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: handleDrop,
        accept: {
            'application/pdf': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        },
        multiple: false
    });

    const handleCustomButton = () => {
        if (customButton + websiteButton + phoneButton < 3) {
            setCustomButton((prevCount) => prevCount + 1);
        }
    };

    const handleCustomButtonClose = () => {
        setCustomButton((prevCount) => prevCount - 1);
    }

    const handleWebsiteButton = () => {
        if (customButton + websiteButton + phoneButton < 3) {
            setWebsiteButton((website) => website + 1)
        }
    }

    const handleWebsiteButtonClose = () => {
        setWebsiteButton((website) => website - 1)
    }

    const handlePhoneButton = () => {
        if (customButton + websiteButton + phoneButton < 3) {
            setPhoneButton((item) => item + 1)
        }
    }

    const handlePhoneButtonClose = () => {
        setPhoneButton((item) => item - 1)
    }

    const initialValues = {
        template_category: '',
        template_name: '',
        template_language: '',
        template_header: '',
        template_header_text: '',
        template_body: '',
        template_footer: '',
        footer_custom_button_text: '',
        footer_website_button_text: '',
        footer_button_url_type: '',
        footer_button_website_url: '',
        footer_call_button_text: '',
        footer_call_country: '',
        footer_button_phone_number: '',
    }

    const validationSchema = Yup.object().shape({
        template_category: Yup.string().required('Category is required'),
        template_name: Yup.string().max(50, 'Template name must be at most 50 characters').required('Template name is required'),
        template_language: Yup.string().required('Language is required'),
        customer_phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true); 
        try {

            setLoading(false);
            setOpenModal(false)
        } catch (error) {
            console.error('Error reading file:', error);
            setLoading(false);
        }
    };

    const now = new Date();
    // Get hours (12-hour format with leading zero)
    let hours = now.getHours() % 12 || 12;  // Convert to 12-hour clock and handle midnight as 12

    // Get minutes with leading zero
    const minutes = now.getMinutes().toString().padStart(2, '0');
    // Get AM/PM indicator
    const meridian = now.getHours() < 12 ? 'AM' : 'PM';
    const formattedTime = `${hours}:${minutes} ${meridian}`;


    return (
        <>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">
                            <h4>New Message Template</h4>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setOpenModal(false)} aria-label="Close"></button>
                    </div>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} >
                        {({ values }) => (
                            <Form>
                                <div className="modal-body">
                                    {activeStep == 1 && (
                                        <>
                                            <div className='template_name'>
                                                <span className='fw-bold fs-5 text-dark'>Category</span>
                                                <p>Choose a category that best  describes your messages template.</p>
                                                <div className={`bg-${!isMarketingCollapsed ? 'light-primary' : ''} p-3 rounded-3 cursor-pointer`} data-bs-toggle="collapse" data-bs-target="#Marketing" aria-expanded={!isMarketingCollapsed} aria-controls="collapseExample" onClick={toggleMarketingCollapse}>
                                                    <div className='d-flex'>
                                                        <div className="symbol symbol-circle symbol-40px">
                                                            <div className="symbol-label bg-light-primary">
                                                                <KTIcon iconName='briefcase' className='fs-1 text-dark' />
                                                            </div>
                                                        </div>
                                                        <div className='ms-4'>
                                                            <h4>Marketing</h4>
                                                            <p>Promotions or information about your business, products or services. Or any message that sont utility or euthentication,</p>
                                                        </div>
                                                    </div>
                                                    <div className="collapse" id="Marketing">
                                                        <div className="ms-4">
                                                            <div className='form-group'>
                                                                <div className="form-check form-check-custom form-check-solid mb-3">
                                                                    <Field type="radio" name="template_category" value="Customs" id="Marketing_Customs" className="form-check-input" />
                                                                    <label htmlFor="Marketing_Customs" className='cursor-pointer'>
                                                                        <span className='d-flex flex-column ms-5'>
                                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                                Custom
                                                                            </span>
                                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                                Send promotional offers, announcements and more to increase awareness and engagement.
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className='form-check form-check-custom form-check-solid'>
                                                                    <Field type="radio" name="template_category" value="Form" id="Form" className="form-check-input" />
                                                                    <label htmlFor="Form" className='cursor-pointer'>
                                                                        <span className='d-flex flex-column ms-5'>
                                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                                Form
                                                                            </span>
                                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                                Send a custom form for lead generation, registration or re-engagement
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className='form-check form-check-custom form-check-solid mt-3'>
                                                                    <Field type="radio" name="template_category" value="Product messages" id="Product messages" className="form-check-input" />
                                                                    <label htmlFor="Product messages" className='cursor-pointer'>
                                                                        <span className='d-flex flex-column ms-5'>
                                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                                Product messages
                                                                            </span>
                                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                                Send messages about your entire catalogue or multiple products from it
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <ErrorMessage name='template_category' className='text-danger mt-2' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`bg-${!isUtilityCollapsed ? 'light-primary' : ''} p-3 rounded-3 mt-5 cursor-pointer`} data-bs-toggle="collapse" data-bs-target="#Utility" aria-expanded={!isUtilityCollapsed} aria-controls="collapseExample" onClick={toggleUtilityCollapse}>
                                                    <div className='d-flex'>
                                                        <div className="symbol symbol-circle symbol-40px">
                                                            <div className="symbol-label bg-light-primary">
                                                                <KTIcon iconName='notification-bing' className='fs-1 text-dark' />
                                                            </div>
                                                        </div>
                                                        <div className='ms-4'>
                                                            <h4>Utility</h4>
                                                            <p>Messages about a specific transaction, account, order or customer request.</p>
                                                        </div>
                                                    </div>
                                                    <div className="collapse" id="Utility">
                                                        <div className="ms-4">
                                                            <div className='form-group'>
                                                                <div className="form-check form-check-custom form-check-solid mb-3">
                                                                    <Field type="radio" name="template_category" value="Custom" id="Utility_Custom" className="form-check-input" />
                                                                    <label htmlFor="Utility_Custom" className='cursor-pointer'>
                                                                        <span className='d-flex flex-column ms-5'>
                                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                                Custom
                                                                            </span>
                                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                                Create your own message.
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className='form-check form-check-custom form-check-solid'>
                                                                    <Field
                                                                        type="radio"
                                                                        name="template_category"
                                                                        value="Order details message"
                                                                        id="Order details message"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label htmlFor="Order details message" className='cursor-pointer'>
                                                                        <span className='d-flex flex-column ms-5'>
                                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                                Order details message
                                                                            </span>
                                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                                Send messages with order details and payment options.
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className='form-check form-check-custom form-check-solid mt-3'>
                                                                    <Field
                                                                        type="radio"
                                                                        name="template_category"
                                                                        value="Order status message"
                                                                        id="Order status message"
                                                                        className="form-check-input"
                                                                    />
                                                                    <label htmlFor="Order status message" className='cursor-pointer'>
                                                                        <span className='d-flex flex-column ms-5'>
                                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                                Order status message
                                                                            </span>
                                                                            <span className='fs-6 fw-bold text-gray-400'>
                                                                                Send messages with order status.
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <ErrorMessage name='template_category' className='text-danger mt-2' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="template_category" component="div" className="text-danger" />
                                            </div>

                                            <div className='template_name'>
                                                <span className='fw-bold fs-5 text-dark'>Name</span>
                                                <p>Name your message template</p>
                                                <Field name="template_name" className="form-control mt-3" />
                                                <ErrorMessage name="template_name" component="div" className="text-danger" />
                                            </div>

                                            <div className='template_name'>
                                                <div className='form-group'>
                                                    <span className='fw-bold fs-5 text-dark'>Languages</span>
                                                    <p>Choose languages for your message template.</p>
                                                    <Field as="select" name="template_language" aria-label="Select example" className="form-select" >
                                                        <option value="">Select Language</option>
                                                        <option value="af">Afrikaans</option>
                                                        <option value="sq">Albanian - shqip</option>
                                                        <option value="am">Amharic - አማርኛ</option>
                                                        <option value="ar">Arabic - العربية</option>
                                                        <option value="an">Aragonese - aragonés</option>
                                                        <option value="hy">Armenian - հայերեն</option>
                                                        <option value="ast">Asturian - asturianu</option>
                                                        <option value="az">Azerbaijani - azərbaycan dili</option>
                                                        <option value="eu">Basque - euskara</option>
                                                        <option value="be">Belarusian - беларуская</option>
                                                        <option value="bn">Bengali - বাংলা</option>
                                                        <option value="bs">Bosnian - bosanski</option>
                                                        <option value="br">Breton - brezhoneg</option>
                                                        <option value="bg">Bulgarian - български</option>
                                                        <option value="ca">Catalan - català</option>
                                                        <option value="ckb">Central Kurdish - کوردی (دەستنوسی عەرەبی)</option>
                                                        <option value="zh">Chinese - 中文</option>
                                                        <option value="zh-HK">Chinese (Hong Kong) - 中文（香港）</option>
                                                        <option value="zh-CN">Chinese (Simplified) - 中文（简体）</option>
                                                        <option value="zh-TW">Chinese (Traditional) - 中文（繁體）</option>
                                                        <option value="co">Corsican</option>
                                                        <option value="hr">Croatian - hrvatski</option>
                                                        <option value="cs">Czech - čeština</option>
                                                        <option value="da">Danish - dansk</option>
                                                        <option value="nl">Dutch - Nederlands</option>
                                                        <option value="en">English</option>
                                                        <option value="en-AU">English (Australia)</option>
                                                        <option value="en-CA">English (Canada)</option>
                                                        <option value="en-IN">English (India)</option>
                                                        <option value="en-NZ">English (New Zealand)</option>
                                                        <option value="en-ZA">English (South Africa)</option>
                                                        <option value="en-GB">English (United Kingdom)</option>
                                                        <option value="en-US">English (United States)</option>
                                                        <option value="eo">Esperanto - esperanto</option>
                                                        <option value="et">Estonian - eesti</option>
                                                        <option value="fo">Faroese - føroyskt</option>
                                                        <option value="fil">Filipino</option>
                                                        <option value="fi">Finnish - suomi</option>
                                                        <option value="fr">French - français</option>
                                                        <option value="fr-CA">French (Canada) - français (Canada)</option>
                                                        <option value="fr-FR">French (France) - français (France)</option>
                                                        <option value="fr-CH">French (Switzerland) - français (Suisse)</option>
                                                        <option value="gl">Galician - galego</option>
                                                        <option value="ka">Georgian - ქართული</option>
                                                        <option value="de">German - Deutsch</option>
                                                        <option value="de-AT">German (Austria) - Deutsch (Österreich)</option>
                                                        <option value="de-DE">German (Germany) - Deutsch (Deutschland)</option>
                                                        <option value="de-LI">German (Liechtenstein) - Deutsch (Liechtenstein)</option>
                                                        <option value="de-CH">German (Switzerland) - Deutsch (Schweiz)</option>
                                                        <option value="el">Greek - Ελληνικά</option>
                                                        <option value="gn">Guarani</option>
                                                        <option value="gu">Gujarati - ગુજરાતી</option>
                                                        <option value="ha">Hausa</option>
                                                        <option value="haw">Hawaiian - ʻŌlelo Hawaiʻi</option>
                                                        <option value="he">Hebrew - עברית</option>
                                                        <option value="hi">Hindi - हिन्दी</option>
                                                        <option value="hu">Hungarian - magyar</option>
                                                        <option value="is">Icelandic - íslenska</option>
                                                        <option value="id">Indonesian - Indonesia</option>
                                                        <option value="ia">Interlingua</option>
                                                        <option value="ga">Irish - Gaeilge</option>
                                                        <option value="it">Italian - italiano</option>
                                                        <option value="it-IT">Italian (Italy) - italiano (Italia)</option>
                                                        <option value="it-CH">Italian (Switzerland) - italiano (Svizzera)</option>
                                                        <option value="ja">Japanese - 日本語</option>
                                                        <option value="kn">Kannada - ಕನ್ನಡ</option>
                                                        <option value="kk">Kazakh - қазақ тілі</option>
                                                        <option value="km">Khmer - ខ្មែរ</option>
                                                        <option value="ko">Korean - 한국어</option>
                                                        <option value="ku">Kurdish - Kurdî</option>
                                                        <option value="ky">Kyrgyz - кыргызча</option>
                                                        <option value="lo">Lao - ລາວ</option>
                                                        <option value="la">Latin</option>
                                                        <option value="lv">Latvian - latviešu</option>
                                                        <option value="ln">Lingala - lingála</option>
                                                        <option value="lt">Lithuanian - lietuvių</option>
                                                        <option value="mk">Macedonian - македонски</option>
                                                        <option value="ms">Malay - Bahasa Melayu</option>
                                                        <option value="ml">Malayalam - മലയാളം</option>
                                                        <option value="mt">Maltese - Malti</option>
                                                        <option value="mr">Marathi - मराठी</option>
                                                        <option value="mn">Mongolian - монгол</option>
                                                        <option value="ne">Nepali - नेपाली</option>
                                                        <option value="no">Norwegian - norsk</option>
                                                        <option value="nb">Norwegian Bokmål - norsk bokmål</option>
                                                        <option value="nn">Norwegian Nynorsk - nynorsk</option>
                                                        <option value="oc">Occitan</option>
                                                        <option value="or">Oriya - ଓଡ଼ିଆ</option>
                                                        <option value="om">Oromo - Oromoo</option>
                                                        <option value="ps">Pashto - پښتو</option>
                                                        <option value="fa">Persian - فارسی</option>
                                                        <option value="pl">Polish - polski</option>
                                                        <option value="pt">Portuguese - português</option>
                                                        <option value="pt-BR">Portuguese (Brazil) - português (Brasil)</option>
                                                        <option value="pt-PT">Portuguese (Portugal) - português (Portugal)</option>
                                                        <option value="pa">Punjabi - ਪੰਜਾਬੀ</option>
                                                        <option value="qu">Quechua</option>
                                                        <option value="ro">Romanian - română</option>
                                                        <option value="mo">Romanian (Moldova) - română (Moldova)</option>
                                                        <option value="rm">Romansh - rumantsch</option>
                                                        <option value="ru">Russian - русский</option>
                                                        <option value="gd">Scottish Gaelic</option>
                                                        <option value="sr">Serbian - српски</option>
                                                        <option value="sh">Serbo-Croatian - Srpskohrvatski</option>
                                                        <option value="sn">Shona - chiShona</option>
                                                        <option value="sd">Sindhi</option>
                                                        <option value="si">Sinhala - සිංහල</option>
                                                        <option value="sk">Slovak - slovenčina</option>
                                                        <option value="sl">Slovenian - slovenščina</option>
                                                        <option value="so">Somali - Soomaali</option>
                                                        <option value="st">Southern Sotho</option>
                                                        <option value="es">Spanish - español</option>
                                                        <option value="es-AR">Spanish (Argentina) - español (Argentina)</option>
                                                        <option value="es-419">Spanish (Latin America) - español (Latinoamérica)</option>
                                                        <option value="es-MX">Spanish (Mexico) - español (México)</option>
                                                        <option value="es-ES">Spanish (Spain) - español (España)</option>
                                                        <option value="es-US">Spanish (United States) - español (Estados Unidos)</option>
                                                        <option value="su">Sundanese</option>
                                                        <option value="sw">Swahili - Kiswahili</option>
                                                        <option value="sv">Swedish - svenska</option>
                                                        <option value="tg">Tajik - тоҷикӣ</option>
                                                        <option value="ta">Tamil - தமிழ்</option>
                                                        <option value="tt">Tatar</option>
                                                        <option value="te">Telugu - తెలుగు</option>
                                                        <option value="th">Thai - ไทย</option>
                                                        <option value="ti">Tigrinya - ትግርኛ</option>
                                                        <option value="to">Tongan - lea fakatonga</option>
                                                        <option value="tr">Turkish - Türkçe</option>
                                                        <option value="tk">Turkmen</option>
                                                        <option value="tw">Twi</option>
                                                        <option value="uk">Ukrainian - українська</option>
                                                        <option value="ur">Urdu - اردو</option>
                                                        <option value="ug">Uyghur</option>
                                                        <option value="uz">Uzbek - o‘zbek</option>
                                                        <option value="vi">Vietnamese - Tiếng Việt</option>
                                                        <option value="wa">Walloon - wa</option>
                                                        <option value="cy">Welsh - Cymraeg</option>
                                                        <option value="fy">Western Frisian</option>
                                                        <option value="xh">Xhosa</option>
                                                        <option value="yi">Yiddish</option>
                                                        <option value="yo">Yoruba - Èdè Yorùbá</option>
                                                        <option value="zu">Zulu - isiZulu</option>
                                                    </Field>
                                                    <ErrorMessage name="template_language" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {activeStep == 2 && (
                                        <>
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <div className='d-flex justify-content-between'>
                                                        <h3>Edit Template</h3>
                                                        <div className='fw-bold fs-5 text-dark'>Select Language : <span className="badge badge-danger">English</span></div>
                                                    </div>
                                                    <hr />
                                                    <h3>Header <span className='fs-6'>(optional) </span></h3>
                                                    <p>Add a title or choose which type of media you'll use for this header.</p>
                                                    <div className="form-group w-50 mb-5">
                                                        <Field as="select" name="template_header" aria-label="Select example" className="form-select" >
                                                            <option value='None'>None</option>
                                                            <option value='Text'>Text</option>
                                                            <option value='Media'>Media</option>
                                                        </Field>
                                                    </div>
                                                    {values.template_header === 'Text' && (
                                                        <>
                                                            <Field type="text" name="template_header_text" className="form-control" placeholder="Type header text..." />
                                                            <ErrorMessage name="template_header_text" component="div" className="text-danger" />
                                                        </>
                                                    )}

                                                    {values.template_header === 'Media' && (
                                                        <>
                                                            <div className='mb-5'>
                                                                <input type="radio" className="btn-check" name="media_type" checked={mediaType === 'image'} value='image' id="image1" autoComplete="off" onChange={handleMediaTypeChange} />
                                                                <label className="btn btn-secondary" htmlFor="image1">
                                                                    <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/image_upload.png')} className='w-75px' />
                                                                    <h4>Image</h4>
                                                                </label>
                                                                <input type="radio" className="btn-check" name="media_type" value='video' id="image2" autoComplete="off"
                                                                    checked={mediaType === 'video'} onChange={handleMediaTypeChange} />
                                                                <label className="btn btn-secondary ms-5" htmlFor="image2">
                                                                    <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/video_play.png')} className='w-75px' />
                                                                    <h4>Video</h4>
                                                                </label>
                                                                <input type="radio" className="btn-check" name="media_type" value='document' id="image3" autoComplete="off"
                                                                    checked={mediaType === 'document'} onChange={handleMediaTypeChange} />
                                                                <label className="btn btn-secondary ms-5" htmlFor="image3">
                                                                    <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/pdf_image.png')} className='w-75px' />
                                                                    <h4>Document</h4>
                                                                </label>
                                                            </div>
                                                        </>
                                                    )}
                                                    {values.template_header === 'Media' && (
                                                        <>
                                                            {mediaType === 'image' && (
                                                                <div className='d-flex'>
                                                                    <div {...getRootProps1({ className: "dropzone" })} >
                                                                        <input className="input-zone" {...getInputProps1()} name='back_image' />
                                                                        <div className="text-center">
                                                                            <p className="dropzone-content">
                                                                                Choose JPG or PNG File
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='ms-5'>
                                                                        {imageUpload.map(file => (
                                                                            <div key={file.name} style={{ margin: '10px' }}>
                                                                                <p>{file.name}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {mediaType === 'video' && (
                                                                <>
                                                                    <div {...getRootProps({ className: "dropzone" })} >
                                                                        <input {...getInputProps()} />
                                                                        <p>Drag 'n' drop a video file, or click to select a file</p>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {mediaType === 'document' && (
                                                                <>
                                                                    <div {...getRootProps2({ className: "dropzone" })}>
                                                                        <input type="file" id="upload_file" {...getInputProps2()} />
                                                                        <div className="text-center">
                                                                            <p className="dropzone-content">
                                                                                Drag’n’drop or click to select files
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {pdfUpload.map(file => (
                                                                        <div key={file.name} className='mt-5'>
                                                                            <p className='badge bg-danger text-white p-3'> {file.name}</p>
                                                                        </div>
                                                                    ))}
                                                                    {pdfsizeError && <div className="text-danger mt-2">PDF file size should be less than 2MB.</div>}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="form-group mt-5">
                                                        <h3>Body</h3>
                                                        <p>Enter the text for your message in the language that you've selected</p>
                                                        <Field
                                                            name="template_body"
                                                            className="form-control"
                                                            as="textarea"
                                                            placeholder="Type your message template..."
                                                            onChange={handleTextAreaChange}
                                                            value={templateBody}
                                                        />
                                                        <ErrorMessage name="template_body" component="div" className="text-danger" />
                                                        <div className='d-flex justify-content-between mt-5'>
                                                            <div className=''>
                                                                <p>Characters: {templateBody.length}/1024</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='mt-2 me-5'>
                                                                    <EmojiPickerComponent setTemplateBody={setTemplateBody} />
                                                                </div>
                                                                <div>
                                                                    <h3 className='mt-2 me-5 cursor-pointer' onClick={handleStarClick}>B</h3>
                                                                </div>
                                                                <div>
                                                                    <button className="btn btn-sm btn-light-primary" onClick={handleAddVariable}>
                                                                        <KTIcon iconName='plus' className='fs-2' /> Add variable
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {renderVariableInputs().length > 0 ? (
                                                            <div className='template_name'>
                                                                <h4>Samples for body content</h4>
                                                                <p>To help us review your message template, please add an example for each variable in your body text. Do not use real customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services</p>
                                                                <h4>Body</h4>
                                                                {renderVariableInputs()}
                                                            </div>
                                                        ) : ''}
                                                    </div>

                                                    <div className="form-group mt-5">
                                                        <h3>Footer <span className='fs-6'>(optional) </span></h3>
                                                        <p>Add a short line of text to the bottom of your message template</p>
                                                        <Field
                                                            name="template_footer"
                                                            className="form-control"
                                                            as="textarea"
                                                            placeholder="Type something..."
                                                        />
                                                        <ErrorMessage name="template_footer" component="div" className="text-danger" />
                                                        <p>Characters:0/60</p>
                                                    </div>
                                                    <div className="form-group mt-5">
                                                        <h3>Buttons</h3>
                                                        <p>Create buttons that let customers respond to your message or take action</p>
                                                        <div className="dropdown">
                                                            <a className="btn btn-sm btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <KTIcon iconName='plus' className='fs-2' />
                                                                Add a button
                                                            </a>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                <li className="dropdown-item">
                                                                    <h5>Quick reply buttons</h5>
                                                                </li>
                                                                <li>
                                                                    <span className="dropdown-item" onClick={handleCustomButton}>Custom</span>
                                                                </li>
                                                                <li className="dropdown-item">
                                                                    <h5>Call-to-action buttons</h5>
                                                                </li>
                                                                <li><span className="dropdown-item" onClick={handleWebsiteButton}>Visit Website</span></li>
                                                                <li><span className="dropdown-item" onClick={handlePhoneButton}>Call Phone Number</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {Array.from({ length: customButton }).map((_, index) => (
                                                        <div key={index} className='template_footer_button'>
                                                            <span className='fw-bold fs-3 text-dark'>
                                                                <i className="bi bi-arrow-down-up text-dark fs-2 pe-2" />  Quick reply
                                                            </span>
                                                            <div className='float-end cursor-pointer' onClick={handleCustomButtonClose}><KTIcon iconName='cross-square' className='fs-1 text-danger' /></div>
                                                            <div className='template_footer_button_text'>
                                                                <span className='fw-bold fs-5 text-dark'>Button text</span>
                                                                <Field name="footer_custom_button_text" className="form-control mt-3" />
                                                                <ErrorMessage name="footer_custom_button_text" component="div" className="text-danger" />
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {websiteButton > 0 || phoneButton > 0 ? (
                                                        <>
                                                            <div className='template_footer_button'>
                                                                <span className='fw-bold fs-3 text-dark'>
                                                                    <i className="bi bi-arrow-down-up text-dark fs-2 pe-2" />  Call to action
                                                                </span>

                                                                {Array.from({ length: websiteButton }).map((_, index) => (
                                                                    <>
                                                                        <div key={index} className='fw-bold fs-5 text-dark text-center'>Type of action : <span className="badge badge-primary">Visit Website </span>
                                                                            <div className='float-end cursor-pointer' onClick={handleWebsiteButtonClose}><KTIcon iconName='cross-square' className='fs-1 text-danger' /></div>
                                                                        </div>
                                                                        <div className='template_footer_button_text d-flex'>
                                                                            <div className='ms-2 me-2'>
                                                                                <label htmlFor="footer_website_button_text" className='form-label required fw-bold fs-5'>Button text</label>
                                                                                <Field name="footer_website_button_text" className="form-control mt-3" />
                                                                                <ErrorMessage name="footer_website_button_text" component="div" className="text-danger" />
                                                                            </div>
                                                                            <div className='me-2'>
                                                                                <label htmlFor="footer_button_url_type" className='form-label required fw-bold fs-5'>URL type</label>
                                                                                <Field name="footer_button_url_type" as="select" className="form-select mt-3">
                                                                                    <option value="static">Static</option>
                                                                                    <option value="dynamic">Dynamic</option>
                                                                                    <option value="93">Afghanistan +93</option>
                                                                                </Field>
                                                                                <ErrorMessage name="footer_button_url_type" component="div" className="text-danger" />
                                                                            </div>
                                                                            <div className='form-group'>
                                                                                <label htmlFor="footer_button_website_url" className='form-label required fw-bold fs-5'>Website URL</label>
                                                                                <Field name="footer_button_website_url" className="form-control mt-3" />
                                                                                <ErrorMessage name="footer_button_website_url" component="div" className="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}

                                                                {Array.from({ length: phoneButton }).map((_, index) => (
                                                                    <>
                                                                        <div key={index} className='fw-bold fs-5 text-dark text-center'>Type of action : <span className="badge badge-info">Call Phone Number</span>
                                                                            <div className='float-end cursor-pointer' onClick={handlePhoneButtonClose}><KTIcon iconName='cross-square' className='fs-1 text-danger' /></div>
                                                                        </div>
                                                                        <div className='template_footer_button_text d-flex mt-5'>
                                                                            <div className='ms-2 me-2'>
                                                                                <span className='fw-bold fs-5 text-dark'></span>
                                                                                <label htmlFor="footer_call_button_text" className='form-label required fw-bold fs-5'>Button text</label>
                                                                                <Field name="footer_call_button_text" className="form-control mt-3" />
                                                                                <ErrorMessage name="footer_call_button_text" component="div" className="text-danger" />
                                                                            </div>
                                                                            <div className='me-2'>
                                                                                <label htmlFor="footer_call_country" className='form-label required fw-bold fs-5'>Country</label>
                                                                                <Field name="footer_call_country" as="select" className="form-select mt-3">
                                                                                    <option value="91">India +91</option>
                                                                                    <option value="93">Afghanistan +93</option>
                                                                                    <option value="54">Argentina +54</option>
                                                                                    <option value="374">Armenia +374</option>
                                                                                    <option value="297">Aruba +297</option>
                                                                                    <option value="61">Australia +61</option>
                                                                                </Field>
                                                                                <ErrorMessage name="footer_call_country" component="div" className="text-danger" />
                                                                            </div>
                                                                            <div>
                                                                                <label htmlFor="footer_button_phone_number" className='form-label required fw-bold fs-5'>Phone number</label>
                                                                                <Field name="footer_button_phone_number" className="form-control mt-3" />
                                                                                <ErrorMessage name="footer_button_phone_number" component="div" className="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </>
                                                    ) : ''}
                                                </div>
                                                <div className='col-md-4'>
                                                    <div>
                                                        <div className='card'>
                                                            <div className='card-header' style={{ background: '#008069' }}>
                                                                <div className='card-title'>
                                                                    <div className='symbol symbol-30px symbol-circle mb-2 me-3'>
                                                                        <img alt='Pic' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                                                                    </div>
                                                                    <div className='d-flex justify-content-center flex-column me-2'>
                                                                        <span className='fs-4 fw-bolder text-white'>
                                                                            Message
                                                                        </span>
                                                                        <small className='text-white'>Preview</small>
                                                                    </div>
                                                                </div>
                                                                <div className='card-toolbar'>
                                                                    <img alt='video_call_icon' src={toAbsoluteUrl('/media/whatsapp/video_call.png')} className='w-25px' />
                                                                    <img alt='phone_icon' src={toAbsoluteUrl('/media/whatsapp/phone.png')} className='w-20px ms-3' />
                                                                    <img alt='menu_icon' src={toAbsoluteUrl('/media/whatsapp/menu_dot.png')} className='w-20px ms-3' />
                                                                </div>
                                                            </div>
                                                            <div className='card-body' style={{ background: '#ECE5DD', height: "100vh" }}>
                                                                <div className='template_message_preview'>
                                                                    <h4> {values.template_header_text} </h4>
                                                                    <div dangerouslySetInnerHTML={{ __html: templateBody }} style={{ whiteSpace: 'pre-wrap' }} />
                                                                    <h5>{values.template_footer}</h5>
                                                                    <div className='template_message_preview_time'> {formattedTime} </div>
                                                                    <hr />
                                                                    <div className='d-flex justify-content-center align-items-center text-primary fw-bolder'> <KTIcon iconName='exit-right-corner' className='fs-2 me-3 text-primary' />{values.footer_website_button_text}</div>
                                                                    <hr />
                                                                    <div className='d-flex justify-content-center align-items-center text-primary mt-3 fw-bolder'> <i className="bi bi-telephone fs-2 me-3 text-primary" />{values.footer_call_button_text}</div>
                                                                    <hr />
                                                                    <div className='d-flex justify-content-center align-items-center text-primary mt-3 fw-bolder'> <KTIcon iconName='arrow-left' className='fs-2 me-3 text-primary' />{values.footer_custom_button_text}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="modal-footer">
                                        {activeStep == 1 ? null : (
                                            <button
                                                onClick={() => setActiveStep((prev) => Math.max(prev - 1))}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                                Back
                                            </button>
                                        )}

                                        <button
                                            type="submit"
                                            className="btn btn-lg btn-primary me-3"
                                            onClick={(e) => {
                                                if (activeStep !== 2) {
                                                    e.preventDefault(); // Prevent the default form submission 
                                                    setActiveStep((prev) => Math.min(prev + 1, 2));
                                                    // Additional actions for form submission on the last step
                                                } else {
                                                    // Proceed to the next step
                                                    setActiveStep((prev) => Math.min(prev + 1, 2));
                                                }
                                            }}
                                        >
                                            {loading ? (
                                                <>
                                                    Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </>
                                            ) : (
                                                <>
                                                    {activeStep == 2 ? 'Submit' : 'Next'}<KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik >
                </div >
            </div >
        </>
    )
}

export default CreateTemplate 