import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    handlePageChange: (page: number) => void;
  }  

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, handlePageChange }) => {
  if (totalPages <= 1) {
    return null; // Don't render pagination if there's only one page
  }

  const firstPage = Math.max(1, currentPage - 1);
  const lastPage = Math.min(totalPages, currentPage + 1);

  return (
    <ul className="pagination justify-content-end">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
        </li>
        {firstPage > 1 && (
            <>
                <li className="page-item">
                    <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                </li>
                {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
            </>
        )}
        {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
            <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
            </li>
        ))}
        {lastPage < totalPages && (
            <>
                {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                <li className="page-item">
                    <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                </li>
            </>
        )}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
        </li>
    </ul>
);

};

export default Pagination;