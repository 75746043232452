import React from 'react'

const Template = ({ apiData,handleSelectTemplate,handleBackButtonTemplate }: any) => {
    return (
        <>
          <h5 className='text-muted'>
            <i className="bi bi-arrow-left fs-3 text-dark pe-3 cursor-pointer" onClick={handleBackButtonTemplate}></i> Select Segment <i className="bi bi-chevron-right fs-3 text-dark pe-3"></i>
            <span className='text-dark'>Select template</span>
          </h5>
            {apiData.data.map((item:any) => (
                <div className="row mt-5">
                    <div className='col-md-8 offset-md-2 card p-8'>
                        <div key={item.id} className='bg-light p-8'>
                            <div className='d-flex justify-content-between'>
                                <h4>{item.name}</h4>
                                <button className='btn btn-sm btn-light-primary border border-primary' onClick={() => handleSelectTemplate(item)}>Select</button>
                            </div>
                            <p>
                                {item.components.map((component:any, index:any) => (
                                    <>
                                        <p className='badge badge-danger text-white'>{component?.format}</p>
                                        <div key={index} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: component.text }} />
                                    </>
                                ))}
                            </p >
                        </div>
                        <div className='d-flex mt-5 ms-5'>
                            <h6 className='fw-bold text-muted me-5'>Language : <span className='fw-bold fs-6 text-dark'>{item.language}</span></h6>
                            <h6 className='fw-bold text-muted'>Category Type: <span className='fw-bold fs-6 text-dark'>{item.category}</span></h6>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Template