import React from 'react'; 

// TypeScript type for the props
interface AlertFormProps {
    subscriptionEndDate: string;
}

// Main component
const AlertForm: React.FC<AlertFormProps> = ({ subscriptionEndDate }) => {

    // Function to parse the date string in the format "DD-MM-YYYY"
    function parseDate(dateStr: string): Date | null {
        if (!dateStr) return null;
        const [day, month, year] = dateStr.split('-').map(Number);
        if (day && month && year) {
            return new Date(year, month - 1, day);
        }
        return null;
    }

    // Function to format the date as "DD-MM-YYYY"
    function formatDate(date: Date): string {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    // Function to get the current date formatted as "DD-MM-YYYY"
    function getCurrentDateStr(): string {
        const today = new Date();
        return formatDate(today);
    }

    const endDate = parseDate(subscriptionEndDate);

    if (!endDate) {
        // console.error("Invalid subscription end date format.");
        return null;
    }
    // Calculate the dates 15 days and 7 days before the end date
    const date15DaysBefore = new Date(endDate);
    date15DaysBefore.setDate(endDate.getDate() - 15);

    const date14DaysBefore = new Date(endDate);
    date14DaysBefore.setDate(endDate.getDate() - 14);

    const date13DaysBefore = new Date(endDate);
    date13DaysBefore.setDate(endDate.getDate() - 13);

    const date12DaysBefore = new Date(endDate);
    date12DaysBefore.setDate(endDate.getDate() - 12);

    const date11DaysBefore = new Date(endDate);
    date11DaysBefore.setDate(endDate.getDate() - 11);

    const date10DaysBefore = new Date(endDate);
    date10DaysBefore.setDate(endDate.getDate() - 10);

    const date9DaysBefore = new Date(endDate);
    date9DaysBefore.setDate(endDate.getDate() - 9);

    const date8DaysBefore = new Date(endDate);
    date8DaysBefore.setDate(endDate.getDate() - 8);

    const date7DaysBefore = new Date(endDate);
    date7DaysBefore.setDate(endDate.getDate() - 7);

    const date6DaysBefore = new Date(endDate);
    date6DaysBefore.setDate(endDate.getDate() - 6);

    const date5DaysBefore = new Date(endDate);
    date5DaysBefore.setDate(endDate.getDate() - 5);

    const date4DaysBefore = new Date(endDate);
    date4DaysBefore.setDate(endDate.getDate() - 4);

    const date3DaysBefore = new Date(endDate);
    date3DaysBefore.setDate(endDate.getDate() - 3);

    const date2DaysBefore = new Date(endDate);
    date2DaysBefore.setDate(endDate.getDate() - 2);

    const date1DaysBefore = new Date(endDate);
    date1DaysBefore.setDate(endDate.getDate() - 1);

    const dateDayBefore = new Date(endDate);
    dateDayBefore.setDate(endDate.getDate() - 0);

    // Format the dates as "DD-MM-YYYY"
    const date15DaysBeforeStr = formatDate(date15DaysBefore);
    const date14DaysBeforeStr = formatDate(date14DaysBefore);
    const date13DaysBeforeStr = formatDate(date13DaysBefore);
    const date12DaysBeforeStr = formatDate(date12DaysBefore);
    const date11DaysBeforeStr = formatDate(date11DaysBefore);
    const date10DaysBeforeStr = formatDate(date10DaysBefore);
    const date9DaysBeforeStr = formatDate(date9DaysBefore);
    const date8DaysBeforeStr = formatDate(date8DaysBefore);
    const date7DaysBeforeStr = formatDate(date7DaysBefore);
    const date6DaysBeforeStr = formatDate(date6DaysBefore);
    const date5DaysBeforeStr = formatDate(date5DaysBefore);
    const date4DaysBeforeStr = formatDate(date4DaysBefore);
    const date3DaysBeforeStr = formatDate(date3DaysBefore);
    const date2DaysBeforeStr = formatDate(date2DaysBefore);
    const date1DaysBeforeStr = formatDate(date1DaysBefore);
    const dateDayBeforeStr = formatDate(dateDayBefore);

    // Get the current date
    const currentDateStr = getCurrentDateStr();

    // Determine if the alert should be shown
    const showAlert = currentDateStr === date15DaysBeforeStr
        || currentDateStr === date14DaysBeforeStr
        || currentDateStr === date13DaysBeforeStr
        || currentDateStr === date12DaysBeforeStr
        || currentDateStr === date11DaysBeforeStr
        || currentDateStr === date10DaysBeforeStr
        || currentDateStr === date9DaysBeforeStr
        || currentDateStr === date8DaysBeforeStr
        || currentDateStr === date7DaysBeforeStr
        || currentDateStr === date6DaysBeforeStr
        || currentDateStr === date5DaysBeforeStr
        || currentDateStr === date4DaysBeforeStr
        || currentDateStr === date3DaysBeforeStr
        || currentDateStr === date2DaysBeforeStr
        || currentDateStr === date1DaysBeforeStr
        || currentDateStr === dateDayBeforeStr

    let message = "";
    if (currentDateStr === date15DaysBeforeStr) {
        message = "15 days.";
    } else if (currentDateStr === date14DaysBeforeStr) {
        message = "14 days.";
    } else if (currentDateStr === date13DaysBeforeStr) {
        message = "13 days.";
    } else if (currentDateStr === date12DaysBeforeStr) {
        message = "12 days.";
    } else if (currentDateStr === date11DaysBeforeStr) {
        message = "11 days.";
    } else if (currentDateStr === date12DaysBeforeStr) {
        message = "12 days.";
    } else if (currentDateStr === date11DaysBeforeStr) {
        message = "11 days.";
    } else if (currentDateStr === date10DaysBeforeStr) {
        message = "10 days.";
    } else if (currentDateStr === date9DaysBeforeStr) {
        message = "9 days.";
    } else if (currentDateStr === date8DaysBeforeStr) {
        message = "8 days.";
    } else if (currentDateStr === date7DaysBeforeStr) {
        message = "7 days.";
    } else if (currentDateStr === date6DaysBeforeStr) {
        message = "6 days.";
    } else if (currentDateStr === date5DaysBeforeStr) {
        message = "5 days.";
    } else if (currentDateStr === date4DaysBeforeStr) {
        message = "4 days.";
    } else if (currentDateStr === date3DaysBeforeStr) {
        message = "3 days.";
    } else if (currentDateStr === date2DaysBeforeStr) {
        message = "2 days.";
    } else if (currentDateStr === date1DaysBeforeStr) {
        message = "Tomorrow.";
    } else if (currentDateStr === dateDayBeforeStr) {
        message = "Today";
    }

    return (
        <>
            {showAlert && (
                <div className="alert alert-dismissible bg-danger  d-flex flex-column flex-sm-row p-5 mb-10">
                    <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                        <i className="bi bi-exclamation-triangle-fill fs-2x text-white"></i>
                    </span>
                    <div className="d-flex flex-column text-white pe-0 pe-sm-10">
                        <span className="mb-1 fs-6">
                            Your <strong>Whatsapp Plan</strong> has been expired for <strong>{message}</strong>.
                        </span>
                        <span> Just a friendly reminder to renew your plan.<strong>Upgrade to a plan to unlock new features and continue using the service.</strong></span>
                    </div>
                    <button className="btn bg-white m-2 ms-auto">Renew Now</button>
                </div>
            )}
        </>
    );
};

export default AlertForm;