import { lazy, FC, Suspense, useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/dashboard/Dashboard'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Contact from '../pages/Contact/Contact'
import WhatsappPage from '../modules/whatsapp/WhatsappPage'
import EmailConfigure from '../modules/whatsapp/pages/Integration/pages/EmailConfigure'
import SMSConfigure from '../modules/whatsapp/pages/Integration/pages/SMSConfigure'
import Razorpay from '../modules/whatsapp/pages/Integration/pages/Razorpay'
import WhatsappConfigure from '../modules/whatsapp/pages/Settings/pages/WhatsappConfigure'
import Catalogue from '../modules/whatsapp/pages/Settings/pages/CatalogueConfigure'
import WhatsappStore from '../modules/whatsapp/pages/Settings/pages/WhatsappStore'
import Orders from '../pages/orders/Orders'
import OrdersReport from '../modules/report/OrdersReport'
import SubscriptionAlert from '../modules/subscription/SubscriptionAlert'
import { AccountDetails } from '../pages/Interface'
import { UserDeatils_Post } from '../modules/auth/core/_requests'
import { useAuth } from '../modules/auth'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const { currentUser } = useAuth()
  const [details, setDetails] = useState<AccountDetails>() 
  const [userToken] = useState(currentUser?.api_token || '')
  const [CustomerId] = useState(currentUser?.cust_id || '')
  

  const fetchUserDetails = async (user_token: any, user_id: any) => {
    try {
      const response = await UserDeatils_Post(user_token, user_id);
      setDetails(response.data); 
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails(userToken, CustomerId);
  }, []);

  const currentDate = new Date().toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
  const subscriptionEndDateStr: any = details?.subscriptions_end;
  // const newDateStr: any = "17-08-2024";
  function parseDate(dateStr: any) {
    const [day, month, year] = dateStr.split('-');
    return new Date(`${year}-${month}-${day}`);
  }
  let CheckExpiryDate: any
  if (subscriptionEndDateStr && currentDate) {
    const subscriptionEndDate = parseDate(subscriptionEndDateStr);
    const newDate = parseDate(currentDate);
    CheckExpiryDate = newDate <= subscriptionEndDate;
  } else {
    <></>
  }

  if (CheckExpiryDate === false) {
    return <SubscriptionAlert details={details} />
  }
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/integration/email" element={<EmailConfigure />} />
        <Route path="/integration/sms" element={<SMSConfigure />} />
        <Route path="/integration/razorpay" element={<Razorpay />} />
        <Route path="/settings/whatsapp" element={<WhatsappConfigure />} />
        <Route path="/settings/catalogue" element={<Catalogue />} />
        <Route path="/settings/whatsapp-store" element={<WhatsappStore />} />
        <Route path="/report" element={<OrdersReport />} />
        <Route path="/order-status/:order_status" element={<Orders />} />
        <Route path="/total-orders" element={<Orders />} />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='whatsapp/*'
          element={
            <SuspensedView>
              {CheckExpiryDate == false ? (
                <SubscriptionAlert details={details} />
              ) : (
                <WhatsappPage />
              )}
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-success')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
