import { useState, useEffect } from 'react'

const Greetings = () => {
  const [timeOfDay, setTimeOfDay] = useState<string>('');

  useEffect(() => {
    const currentHour: number = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      setTimeOfDay('morning');
    } else if (currentHour >= 12 && currentHour < 17) {
      setTimeOfDay('afternoon');
    } else if (currentHour >= 17 && currentHour < 21) {
      setTimeOfDay('evening');
    } else {
      setTimeOfDay('night');
    }
  }, []);

  let message: any;

  switch (timeOfDay) {
    case 'morning':
      message = '🌅 Good Morning!';
      break;
    case 'afternoon':
      message = '🌞 Good Afternoon!';
      break;
    case 'evening':
      message = '🌃 Good Evening!';
      break;
    case 'night':
      message = '🌠 Good Night!';
      break;
    default:
      message = 'Hello!';
  }

  return (
  <div>{message}</div>

  )
};
export {Greetings};