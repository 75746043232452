import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { Field, Form, Formik, FormikValues } from 'formik'
import { whatspp_chat_image_post } from "../../../../auth/core/_requests"
import { KTIcon } from '../../../../../../_metronic/helpers'; 

const ChatDocument = ({ chatuser, setDocumentModel, chatStart }: any) => {
    const [loading, setLoading] = useState(false)
    const [pdfUpload, setPdfUpload] = useState<File[]>([]);
    const [pdfsizeError, setPdfsizeError] = useState(false);
    const sender_id = "919943497572";


    const handleDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if (file.size <= 2 * 1024 * 1024) { // Checking if file size is less than 2MB
                setPdfUpload([file]);
                setPdfsizeError(false);
            } else {
                setPdfsizeError(true);
            }
        }
    };

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: handleDrop,
        accept: {
            'application/pdf': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':[], 
        },
        multiple: false
    });

    const handleClear = () => {
        setPdfUpload([]); // Clear uploaded images
    };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        if (!pdfUpload[0]) {
            setPdfsizeError(true);
            return;
        } else {
            setPdfsizeError(false);
        }
        setLoading(true);
        try {
            const ChatMedia = new FormData();
            if (pdfUpload.length > 0) {
                ChatMedia.append("chat_media", pdfUpload[0]);
            }
            const response = await whatspp_chat_image_post(
                ChatMedia,
                'document',
                values.message_caption ? values.message_caption : '',
                chatuser,
                sender_id
            );
            setLoading(false);
            chatStart(chatuser);
            setDocumentModel(false)
            actions.resetForm();
            setPdfUpload([]);
        } catch (error) {
            console.error('Error reading file:', error);
            setLoading(false);
        }
    };
    return (
        <>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">
                            <h3>Document Upload</h3>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setDocumentModel(false)} aria-label="Close"></button>
                    </div>
                    <Formik initialValues={{ file: null }} onSubmit={handleSubmit} >
                        <Form>
                            <div className="modal-body">
                                <div>
                                    <div {...getRootProps1({ className: "dropzone" })}>
                                        <input type="file" id="upload_file" {...getInputProps1()} />
                                        <div className="text-center">
                                            <p className="dropzone-content">
                                                Drag’n’drop or click to select files
                                            </p>
                                        </div>
                                    </div>
                                    {pdfsizeError && <div className="text-danger mt-2">PDF file size should be less than 2MB.</div>}

                                    {pdfUpload.map(file => (
                                        <div key={file.name} className='mt-5'>
                                            <p className='badge bg-primary text-white p-3'> {file.name} <span onClick={() => handleClear()}> <KTIcon iconName='cross-square' className='ms-5 cursor-pointer fs-1 text-white' /></span></p>
                                        </div>
                                    ))}
                                    {pdfUpload.length > 0 && (
                                        <div className="form-group mt-5 mb-3">
                                            <Field
                                                name="message_caption"
                                                className="form-control w-50"
                                                as="textarea"
                                                placeholder="Add a Caption..."
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-footer">
                                {/* <div className="row col-12"> */}
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Send'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                {/* </div> */}
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default ChatDocument 