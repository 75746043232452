import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Link } from 'react-router-dom'

const Api = () => {
  return (
    <>
      <div className='row g-6 g-xl-9'>
        <div className='col-md-6 col-xxl-3'>
          <div className='card'>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/whatsapp_business.png')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
              Whatsapp Business
              </a>
              <div className='fw-bold text-gray-400 mb-6'>Active</div>
              <Link to='/settings/whatsapp'>
                <button className='btn btn-sm btn-primary fw-bolder'>
                  Configure
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-xxl-3'>
          <div className='card '>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/catalogue.png')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                Catalogue
              </a>
              <div className='fw-bold text-gray-400 mb-6'>Active</div>
              <Link to='/settings/catalogue'>
                <button className='btn btn-sm btn-primary fw-bolder'>
                  Configure
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-xxl-3'>
          <div className='card '>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/whatsapp/app-store.png')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
              Whatsapp Store
              </a>
              <div className='fw-bold text-gray-400 mb-6'>Active</div>
              <Link to='/settings/whatsapp-store'>
                <button className='btn btn-sm btn-primary fw-bolder'>
                  Configure
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Api