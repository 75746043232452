import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
 
 
const SubscriptionAlertContent = ({ details }: any) => {
    return (
        <div className='row w-100'>
            <>
                <div className='col-md-6 d-flex align-items-center justify-content-center'>
                    <div>
                        <KTIcon iconName='information-2' className='fs-6x text-warning' />
                        <h1>Your Subscription Expired</h1>
                        <h6 className='mt-5 lh-lg'>WhatsApp Platform Access disabled due to subscription is expired. Kindly pay to restore access.</h6>
                        <span className='text-dark fw-bold'>  Subscription Period -  </span>
                        <span className='badge bg-info text-white p-3'>{details?.subscriptions_start} To {details?.subscriptions_end}</span>
                        <button className="btn btn-danger">
                            Contact Support : +919500802940
                        </button>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img alt='Pic' src={toAbsoluteUrl('/media/stock/disconnect.jpg')} className='w-100' />
                    </div>
                </div>

            </>
        </div>
    );
};

export default SubscriptionAlertContent;