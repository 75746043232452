import React from 'react'
import {  Route, Routes, Outlet } from 'react-router-dom'
import { PageLink } from '../../../_metronic/layout/core'  
import Conversation from './pages/Conversation/Conversation'
import Broadcast from './pages/Broadcast/Broadcast'
import Template from './pages/Template/Template'
import Catalogue from './pages/Catalogue/Catalogue'
import Integration from './pages/Integration/Integration'
import Api from './pages/Settings/Api'
 
const WhatsappPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <> 
            <Outlet />
          </>
        }
      />
      <Route
        path='conversation'
        element={
          <> 
            <Conversation />
          </>
        }
      />
      <Route
        path='broadcast'
        element={
          <>  
            <Broadcast />
          </>
        }
      />
      <Route
        path='template'
        element={
          <> 
            <Template />
          </>
        }
      />
      <Route
        path='catalogue'
        element={
          <> 
            <Catalogue />
          </>
        }
      />
      <Route
        path='integration'
        element={
          <> 
            <Integration />
          </>
        }
      />
      <Route
        path='settings/api'
        element={
          <> 
            <Api />
          </>
        }
      />
    </Routes>
  )
}

export default WhatsappPage
