import React, { useState, useEffect } from 'react';
import { orders_get_reports } from '../auth/core/_requests'; 
import { toAbsoluteUrl } from '../../../_metronic/helpers'; 

interface DataRow {
    order_id: number;
    delivery_charges: string;
    order_created_on: string;
    order_grand_total: string;
    order_gst_amount: string;
    order_gst_perc: string;
    order_payment_status: string;
    order_sub_total: string;
    order_person_name: string;
    order_contact_number: string;
}

const OrdersReport: React.FC = () => {
    const [orderReport, setOrderReport] = useState<DataRow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRecords, setfilteredRecords] = useState<DataRow[]>([]);
    const [paymentStatus, setPaymentStatus] = useState('');
    const fetchOrdersReport = async (order_report: any) => {
        try {
            const response = await orders_get_reports(order_report);
            setOrderReport(response.data.order_report);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchOrdersReport("0");
    }, []);


    const itemsPerPage = 10;
    const totalItems = filteredRecords.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const totalOrders = filteredRecords.slice(startIndex, startIndex + itemsPerPage);
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };


    const renderPagination = () => {
        if (totalPages <= 1) {
            return null; // Don't render pagination if there's only one page
        }

        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(totalPages, currentPage + 1);

        return (
            <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {firstPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                    <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                    </li>
                ))}
                {lastPage < totalPages && (
                    <>
                        {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        const filtered = orderReport?.filter((item) => {
            const fullName = `${item.order_id} ${item.order_person_name}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase()) &&
                (paymentStatus === '' || item.order_payment_status === paymentStatus);
        });
        setfilteredRecords(filtered);
    }, [searchQuery, orderReport, paymentStatus]);


    const handlePaymentStatusChange = (event: any) => {
        setPaymentStatus(event.target.value);
    };

    // // const [flatpickrInstance, setFlatpickrInstance] = useState<Flatpickr.Instance | null>(null);
    // const [ReportFromDate, setReportFromDate] = useState(null);

    // const from_date = (event:any) => {
    //   const dateValue = event.target.value;
    //   // Do something with the date value, such as updating state

    //   console.log(dateValue);
    //   //setReportFromDate(dateValue);
    // };
    return (


        <div className='card'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>ORDER REPORT</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Total {orderReport.length} orders</span>
                </h3>
                <div className='card-toolbar'>
                    <div>
                        <select
                            className='form-select form-select-solid'
                            onChange={handlePaymentStatusChange}
                            value={paymentStatus}
                        >
                            <option value="" >Payment Status</option>
                            <option value="1">Paid</option>
                            <option value="0">Not Paid</option>

                        </select>
                    </div>
                    <div className="d-flex align-items-center position-relative ms-5">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4"><span className="path1"></span><span className="path2"></span></i>
                        <input type="text"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            data-kt-ecommerce-product-filter="search"
                            className="form-control form-control-solid w-250px ps-12"
                            value={searchQuery}
                            placeholder="Search Order Id"
                        />
                    </div>
                </div>
            </div>
            <div className='card-body py-3'>
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (totalOrders.length > 0 ? (
                    <div className='table-responsive'>
                        <table className='table align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold fs-6 bg-light'>
                                    <th className='ps-4 rounded-start text-center'>Order Id</th>
                                    <th > Name</th>
                                    <th > Contact</th>
                                    <th >Subtotal</th>
                                    <th >GST</th>
                                    <th >Delivery Charges</th>
                                    <th > Grand Total</th>
                                    <th >Payment</th>
                                    <th className=' pe-4 rounded-end'>Order On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {totalOrders.map((report, index) => (
                                    <tr key={index}>
                                        <td className='text-dark fw-bold text-hover-primary fs-6 ps-4'>{report.order_id}</td>
                                        <td>{report.order_person_name}</td>
                                        <td>{report.order_contact_number}</td>
                                        <td>{report.order_sub_total}</td>
                                        <td>{report.order_gst_amount} (18%)</td>
                                        <td>{report.delivery_charges}</td>
                                        <td>{report.order_grand_total}</td>
                                        <td>{report.order_payment_status === "1" ? (
                                            <span className='badge bg-success text-white'>Paid</span>
                                        ) : (<span className='badge bg-danger text-white'>No Paid</span>)
                                        }</td>
                                        <td>{report.order_created_on}</td>

                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {renderPagination()}
                    </div>
                ) :
                    <div className="d-flex justify-content-center p-10">
                        <div className="car">
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                            <h3 className="text-center">No orders found</h3>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrdersReport;
