import React, { useEffect, useState } from 'react'
import { Form, Formik, FormikValues, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import { Email__config_post_data, Addons, Email_config_upd_data } from '../../../../../modules/auth/core/_requests'
import Swal from 'sweetalert2';


interface EmailData {
    addon_id: string;
    email_user_name: string;
    email_user_password: string;
    email_smtp_address: string;
    addon_created_on: string;
    addon_status: string;
}

const EmailConfigure = () => {
    const addon_type = "email"
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [addons, setAddons] = useState<EmailData[]>([]);
    const [initialEditValues, setInitialEditValues] = useState(
        {
            addon_id: '',
            email_user_name_upd: '',
            email_user_password_upd: '',
            email_smtp_address_upd: '',
        }
    )

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const initialValues = {
        email_user_name: '',
        email_user_password: '',
        email_smtp_address: '',
    }

    const validationSchema = Yup.object().shape({
        email_user_name: Yup.string().required('User Name is required'),
        email_user_password: Yup.string().required('Password is required'),
        email_smtp_address: Yup.string().required('SMTP Address is required'),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const { data: auth } = await Email__config_post_data(
                values.email_user_name,
                values.email_user_password,
                values.email_smtp_address,
                addon_type,
            );
            actions.resetForm();
            // ADDON_GET("whatsapp");
            setTimeout(() => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
            setShowModal(false)
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const ADDON_GET = async (addon: any) => {
        try {
            const response = await Addons(addon);
            setAddons(response.data.addon_details);
            if (response.data.addon_details) {
                setIsLoading(false);
                const initialData = response.data.addon_details
                const addonMetaData = JSON.parse(initialData[0].addon_meta_data);
                setInitialEditValues({
                    addon_id: initialData.length > 0 ? initialData[0].addon_id : '',
                    email_user_name_upd: addonMetaData.email_user_name,
                    email_user_password_upd: addonMetaData.email_user_password,
                    email_smtp_address_upd: addonMetaData.email_smtp_address,
                })
            }
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        ADDON_GET("email");
    }, []);

    const handleSubmitUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const { data: auth } = await Email_config_upd_data(
                values.addon_id,
                values.email_user_name_upd,
                values.email_user_password_upd,
                values.email_smtp_address_upd,
                addon_type,
            );
            actions.resetForm();
            ADDON_GET("email");
            setTimeout(() => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
            setShowModal(false)
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const initialValuesTo = {
        to_email_address: '',
        message: '',
    }

    const validationSchemaTo = Yup.object().shape({
        to_email_address: Yup.string().email('Invalid email').required('To email address is required'),
        message: Yup.string().required('Message is required'),
    });

    const handleSubmitTo = (values: any) => {
        console.log(values)
    }

    const initialSettingsValues = {
        admin_email_address: '',
        whatsapp_notication: '',
    }

    const validationSettings = Yup.object().shape({
        admin_email_address: Yup.string().email('Invalid email').required('Admin email address is required'),
        cc_email_address: Yup.string().email('Invalid email'),
        bcc_email_address: Yup.string().email('Invalid email'),
        whatsapp_notication: Yup.array().required('Notification is required').test('at-least-one-setting', 'Select at least one Notification', (value) => value && value.length > 0),
    });

    const handleSubmitSettings = (values: any) => {
        console.log(values, 'values')
    }

    return (
        <>
            <div className="row">
                <div className='col-md-8 offset-md-2 card p-8'>
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_4"
                            >
                                Configuration
                            </a>
                        </li>

                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_5"
                            >
                                Test
                            </a>
                        </li>

                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_6"
                            >
                                Settings
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane fade show active" id="kt_tab_pane_4" role="tabpanel">
                            {isLoading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : (addons.length > 0 ? (
                                <>
                                    {showModal ? (
                                        <div>
                                            <Formik initialValues={initialEditValues} onSubmit={handleSubmitUpdate}>
                                                {() => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <Field type="hidden" name="addon_id" className="form-control" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="email_user_name_upd" className='form-label mb-3'>User Name</label>
                                                            <Field type="text" name="email_user_name_upd" className="form-control" />
                                                            <ErrorMessage name="email_user_name_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="form-label mt-3 mb-3" htmlFor="email_user_password_upd" >Password</label>
                                                            <Field type="password" name="email_user_password_upd" className="form-control" />
                                                            <ErrorMessage name="email_user_password_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="form-label mt-3 mb-3" htmlFor="email_smtp_address_upd" >SMTP Address</label>
                                                            <Field type="text" name="email_smtp_address_upd" className="form-control" />
                                                            <ErrorMessage name="email_smtp_address_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className='d-flex justify-content-end mt-5'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='d-flex justify-content-between align-items-center mt-10'>
                                                <div className="mb-10">
                                                    <h5 className='fw-bolder'>Configuration Details</h5>
                                                </div>
                                                <div>
                                                    <button className='btn btn-sm btn-primary' onClick={handleButtonClick}>
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='row mb-7'>
                                                <label className='col-lg-4 fw-bold text-muted'>User Name</label>
                                                <div className='col-lg-8'>
                                                    <span className='fw-bolder fs-6 text-dark'>{initialEditValues.email_user_name_upd}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-4 fw-bold text-muted">Password</label> 
                                                <div className="col-lg-8">
                                                    {showPassword ? (
                                                        <span className="fw-bolder fs-6 text-dark">{initialEditValues.email_user_password_upd}</span>
                                                    ) : (
                                                        <span className="fw-bolder fs-6 text-dark">
                                                            {Array(initialEditValues.email_user_password_upd.length + 1).join('*')}
                                                        </span>
                                                    )}
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {showPassword ? (
                                                            <i className="bi bi-eye-fill fs-5 ms-5"></i>
                                                        ) : (
                                                            <i className="bi bi-eye-slash-fill fs-5 ms-5"></i>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='row mb-7'>
                                                <label className='col-lg-4 fw-bold text-muted'>SMTP Address</label>
                                                <div className='col-lg-8'>
                                                    <span className='fw-bolder fs-6 text-dark'>{initialEditValues.email_smtp_address_upd}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) :
                                <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                                    {() => (
                                        <Form>
                                            <div className="form-group">
                                                <label htmlFor="email_user_name" className='form-label mb-3'>User Name</label>
                                                <Field type="text" name="email_user_name" className="form-control" />
                                                <ErrorMessage name="email_user_name" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="email_user_password" >Password</label>
                                                <Field type="password" name="email_user_password" className="form-control" />
                                                <ErrorMessage name="email_user_password" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="email_smtp_address" >SMTP Address</label>
                                                <Field type="text" name="email_smtp_address" className="form-control" />
                                                <ErrorMessage name="email_smtp_address" component="div" className="text-danger" />
                                            </div>

                                            <div className='d-flex justify-content-end mt-5'>
                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                    {!loading && 'Submit'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div>

                        {/* <div className="tab-pane fade show active" id="kt_tab_pane_4" role="tabpanel">
                            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                                {() => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="email_user_name" className='form-label mb-3'>User Name</label>
                                            <Field type="text" name="email_user_name" className="form-control" />
                                            <ErrorMessage name="email_user_name" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="email_user_password" >Password</label>
                                            <Field type="text" name="email_user_password" className="form-control" />
                                            <ErrorMessage name="email_user_password" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="email_smtp_address" >SMTP Address</label>
                                            <Field type="text" name="email_smtp_address" className="form-control" />
                                            <ErrorMessage name="email_smtp_address" component="div" className="text-danger" />
                                        </div>

                                        <div className='d-flex justify-content-end mt-5'>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div> */}

                        <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                            <Formik validationSchema={validationSchemaTo} initialValues={initialValuesTo} onSubmit={handleSubmitTo}>
                                {() => (
                                    <Form>
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="to_email_address" >To Email Address</label>
                                            <Field type="text" name="to_email_address" className="form-control" />
                                            <ErrorMessage name="to_email_address" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="message" >Message</label>
                                            <Field type="text" name="message" as="textarea" row={5} className="form-control" />
                                            <ErrorMessage name="message" component="div" className="text-danger" />
                                        </div>

                                        <div className='d-flex justify-content-end mt-5'>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
                            <Formik validationSchema={validationSettings} initialValues={initialSettingsValues} onSubmit={handleSubmitSettings}>
                                {() => (
                                    <Form>
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="admin_email_address" >Admin Email Address</label>
                                            <Field type="text" name="admin_email_address" className="form-control" />
                                            <ErrorMessage name="admin_email_address" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="cc_email_address" >CC</label>
                                            <Field type="text" name="cc_email_address" className="form-control" />
                                            <ErrorMessage name="cc_email_address" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="bcc_email_address" >BCC</label>
                                            <Field type="text" name="bcc_email_address" className="form-control" />
                                            <ErrorMessage name="bcc_email_address" component="div" className="text-danger" />
                                        </div>

                                        <label className="form-label mt-3 mb-3">Notifications</label>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="whatsapp_notication" value="New Orders" className="form-check-input" id="New_orders" />
                                            <label className="form-check-label" htmlFor="New_orders">New Orders</label>
                                        </div>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="whatsapp_notication" value="Completed Order" className="form-check-input" id="Completed_order" />
                                            <label className="form-check-label" htmlFor="Completed_order">Completed Order</label>
                                        </div>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="whatsapp_notication" value="Pending Order" className="form-check-input" id="pending_order" />
                                            <label className="form-check-label" htmlFor="pending_order">Pending Order</label>
                                        </div>
                                        <ErrorMessage name="whatsapp_notication" component="div" className="text-danger" />
                                        <div className='d-flex justify-content-end mt-5'>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailConfigure