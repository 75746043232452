import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to="/contacts" fontIcon='bi-layers' title="Contacts" icon='address-book' />
      <SidebarMenuItem to="/orders" fontIcon='bi-layers' title="Orders" icon='delivery-time' />
      <SidebarMenuItem to='whatsapp/conversation' icon='message-text' title='Conversations' />
      <SidebarMenuItem to='whatsapp/broadcast' icon='notification-bing' title='Broadcast' />
      <SidebarMenuItem to='whatsapp/catalogue' title='Catalogue' icon='note-2' />
      <SidebarMenuItem to='whatsapp/template' title='Template' icon='directbox-default' />
      <SidebarMenuItem to='whatsapp/integration' title='Integration' icon='bi bi-link-45deg' />
      <SidebarMenuItemWithSub to='/whatsapp/settings' title='Settings' icon='setting-2'>
        <SidebarMenuItem to='/whatsapp/settings/api' title='Api' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to="/account/overview"
        fontIcon='bi-layers'
        title="My Account"
        icon='profile-circle' />
      <SidebarMenuItem to="/report"
        fontIcon='bi-layers'
        title="Report"
        icon='graph-up' />
    </>
  )
}

export { SidebarMenuMain }
