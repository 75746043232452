import React, { useEffect, useState } from 'react'
import { Form, Formik, ErrorMessage, Field, FormikValues } from 'formik'
import * as Yup from 'yup'
import { KTIcon } from '../../../../../../_metronic/helpers';
import { Link } from 'react-router-dom'
import { SMSdata } from '../../../../../pages/Whatsapp_Interface/Interface';
import { Addons, Wp_store_post_data, Wp_store_upd_data } from '../../../../auth/core/_requests';
import Swal from 'sweetalert2';

const WhatsappStore = () => {
    const addon_type = "waba"
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [addons, setAddons] = useState<SMSdata[]>([]);
    const [initialEditValues, setInitialEditValues] = useState(
        {
            addon_id_upd: '',
            business_id_upd: '',
        }
    )

    const ADDON_GET = async (addon: any) => {
        try {
            const response = await Addons(addon);
            setAddons(response.data.addon_details);
            if (response.data.addon_details) {
                setIsLoading(false);
                const initialData = response.data.addon_details
                const addonMetaData = JSON.parse(initialData[0].addon_meta_data);
                setInitialEditValues({
                    addon_id_upd: initialData.length > 0 ? initialData[0].addon_id : '',
                    business_id_upd: addonMetaData.wp_business_id,
                })
            }
        } catch (error) {
            console.error('Error fetching store:', error);
        }
    };

    useEffect(() => {
        ADDON_GET("waba");
    }, []);

    const initialValuesStore = {
        business_id: '',
    }

    const validationSettings = Yup.object().shape({
        business_id: Yup.string().required('Business ID is required'),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const response = await Wp_store_post_data(
                values.business_id,
                addon_type
            );
            if (response.data === "Successfully Created") {
                actions.resetForm();
                ADDON_GET("waba");
                setLoading(false)
                setShowModal(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Please check issues',
                    showConfirmButton: true,
                    timer: 3000
                });
            }
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const handleSubmitUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const response = await Wp_store_upd_data(
                values.addon_id_upd,
                values.business_id_upd,
                addon_type
            );
            if (response.data === "success") {
                actions.resetForm();
                ADDON_GET("catalogue");
                setLoading(false)
                setShowModal(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Please check issues',
                    showConfirmButton: true,
                    timer: 3000
                });
            }
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const handleButtonClick = () => {
        setShowModal(true);
    };

    return (
        <div>
            <Link to='/whatsapp/settings/api'>
                <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                </button>
            </Link>



            <div className="row">
                <div className='col-md-8 offset-md-2 card p-8'>
                    <>
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (addons.length > 0 ? (
                            <>
                                {showModal ? (
                                    <div>
                                        <Formik initialValues={initialEditValues} onSubmit={handleSubmitUpdate}>
                                            {() => (
                                                <Form>
                                                    <div className="form-group">
                                                        <Field type="hidden" name="addon_id_upd" className="form-control" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="business_id_upd" className='form-label mt-3 mb-3'>Business ID</label>
                                                        <Field type="text" name="business_id_upd" className="form-control" />
                                                        <ErrorMessage name="business_id_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className='d-flex justify-content-end mt-5'>
                                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                                            {!loading && 'Submit'}
                                                            {loading && (
                                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                                    Please wait...{' '}
                                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                ) : (
                                    <>
                                        <div className='d-flex justify-content-between align-items-center mt-10'>
                                            <div className="mb-10">
                                                <h5 className='fw-bolder'>Configuration Details</h5>
                                            </div>
                                            <div>
                                                <button className='btn btn-sm btn-primary' onClick={handleButtonClick}>
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row mb-7'>
                                            <label className='col-lg-4 fw-bold text-muted'>Business ID</label>
                                            <div className='col-lg-8'>
                                                <span className='fw-bolder fs-6 text-dark'>{initialEditValues.business_id_upd}</span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ) :
                            <>
                                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                                    Enter Whatsapp Business ID
                                    <i
                                        className='fas fa-exclamation-circle ms-2 fs-7'
                                        data-bs-toggle='tooltip'
                                        title='Billing is issued based on your selected account type'
                                    ></i>
                                </h2>

                                <div className='text-gray-400 fw-bold fs-6'>
                                    Copy Business ID from Settings page and paste in field below and Hit the
                                    <span className='link-primary fw-bolder'>
                                        {' '}
                                        Connect button
                                    </span>
                                    .
                                </div>
                                <div className='mt-10'>
                                    <Formik validationSchema={validationSettings} initialValues={initialValuesStore} onSubmit={handleSubmit}>
                                        {() => (
                                            <Form>

                                                <div className="form-group">
                                                    <Field type="text" name="business_id" className="form-control" placeholder="Enter Whatsapp Business ID" />
                                                    <ErrorMessage name="business_id" component="div" className="text-danger" />
                                                </div>

                                                <div className='d-flex justify-content-end mt-5'>
                                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                                        {!loading && 'Connect Now'}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Please wait...{' '}
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </>
                        )}
                    </>
                </div>
            </div>
        </div>
    )
}

export default WhatsappStore  
