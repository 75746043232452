/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom' 
import { ForgotPassword } from './components/ForgotPassword' 
import Login  from './components/Login'  
import { Resetpassword } from './components/Resetpassword' 


const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (

    <div>
      <Outlet />
    </div>

  )
}

const AuthPage = () => (
  <Routes> 
    <Route element={<AuthLayout />}>
      <Route index element={<Login />} />
      <Route path='login' element={<Login />} />  
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<Resetpassword />} />  
    </Route>
  </Routes>
)

export { AuthPage }
